<template>
    <div class="col-sm-8">
        <e-datepicker :id="code" :name="code" v-model="input_date" :placeholder="placeholder" :required="required" />
    </div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
    import Navigation from '@/mixins/Navigation.js'

	export default {
		name: "DateInput",
        mixins: [Navigation],
        props:[
            'placeholder',
            'q_id',
            'answer_given',
            'required'
        ],
        data() {
            return {
                input_date: null,
                events_tab: {
                    'inputs::resetChecked': this.resetCheck
                }
            }
        },
		mounted() {
            this.init_component()
		},
        methods: {
            init_component() {
                if(this.answer_given && this.answer_given.val!==null && this.answer_given.key == this.q_id) {
                    this.input_date = this.answer_given.val[0] ? Date.parseTz(this.answer_given.val[0]) : null
                }
                else {
                    this.input_date = new Date()
                }
            },
            resetCheck() {
                this.init_component()
            }
        },
        watch: {
            'input_date' (val) {
                this.$emit('update:answer', { key: this.q_id, val: val ? val.toDateInputValue() : "" })
            }
        }
    }
</script>
